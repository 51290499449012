<template>
  <a type="button" 
    ref="buttonRef" 
    class="ertp-icon-button" 
    role="button"
    tabindex="0"
    :disabled="disabled"
    :aria-disabled="disabled"
    :title="title"
    :aria-label="title"
    data-bs-toggle="tooltip"
    :class='{ "opacity-25": disabled }'
    :href="href"
    @mouseover="mouseover"
    @mouseleave="mouseleave">
    <img 
      class="img-fluid" 
      :class='{ "p-1": !active, "opacity-75": !active }' 
      :src="imgSrc"
      :alt="alt || title"
      width="39"
      height="39"
    >
  </a>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useBootstrap } from '../../composables/bootstrap';

const { 
  getOrCreateTooltipInstance, 
  hideTooltip, 
  setTooltipContent,
  bootstrapLoaded
} = useBootstrap();

const active = ref(false);

const props = defineProps<{
  disabled?: boolean, 
  imgSrc?: string, 
  title?: string,
  href?: string,
  alt?: string
}>();

const buttonRef = ref();

onMounted(() => {
 if (bootstrapLoaded.value) getOrCreateTooltipInstance(buttonRef);
});

onBeforeUnmount(() => {
  hideTooltip(buttonRef);
});

watch([() => props.title, bootstrapLoaded], () => {
  if (props.title && bootstrapLoaded.value) {

    getOrCreateTooltipInstance(buttonRef);

    setTooltipContent(buttonRef, {'.tooltip-inner': props.title || ''});
  }
});

function mouseover() {
  active.value = !props.disabled
}

function mouseleave(event: MouseEvent) {
  active.value = false;
  hideTooltip(buttonRef);
}
</script>

<style lang="scss">
.ertp-icon-button {
  width: 37px;
  min-width: 37px;
}
</style>
